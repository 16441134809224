import intlTelInput from "intl-tel-input";
import { Popover } from "bootstrap";
import Swal from "sweetalert2";
import { route } from 'ziggy-js';
import validate from 'jquery-validation';
import "jquery-validation/dist/additional-methods.js";
import 'owl.carousel';
import { setValidationRules, setInputSanitizer } from "./frontend-validation-scripts.js";
import { initImages } from "./init-images.js";



/** VARIABLES SECTION **/
let typingTimer;
const successMessage = 'success';
const typingInterval = 1000;
const date = new Date();
const readMoreText = "Read More";
const readLessText = "Read Less";
const contactWrap = $(".contact-wrap");
const callForm = $("#call_form");
const emailForm = $("#email_form");
const searchOutputDiv = $("#header-search-output");
const contactUsButton = $("#contact-us-btn");
const emailContactModal = $("#email-modal-form");
const callbackContactModal = $("#callback-modal-form");
const newsletterSubscriptionForm = $("#newsletter_form");
const scrollToTop = $("#scroll");
const dateButtons = $(".js-date-value");
const locationModal = $("#location-select-modal");
const dateModal = $("#date-select-modal");
const locationSelectButton = $("#location_select_search_button");
const dateSelectButton = $("#date_select_search_button");
const locationSelectInput = $("#location_select");
const dateSelectInput = $("#date_select");
const searchSubmitButton = $("#search_submit");
const sortingForm = $("#sorting_form");
const searchInputElement = $("#header-search-value-desk-new, #header-search-value-new");
const registerForm = $("#register_form");
const faqCollapses = $(".faq-collapse");
const readMoreReview = $('.read-more-review-content');
const readMoreTextButton = $(".read_more_text_button");
const readMoreReviewsButton = $("#read_more_reviews_button");
const moreReviewsSection = $(".read-more-review-content");
const readMoreButton = $('.read-more');
const owlCarousel = $('.owl-carousel-basic');

const inquiryForm = $('#modal-enquire-form');
const csrf = $("input[name=_token]");

const priceSortButton = $(".sort-by-price");
const departureSortButton = $(".sort-by-departure");
const priceInputs = $(".price-input");
const sortBy = $("#sort_by");
const dateStart = $("input[name=date-start]").data('value');

const selectSearchDiv = $('.select-search');
const worldDiv = $('.worldwide');

const passwordInput = $('#password');
const passwordConfirmationInput = $('#password_confirmation');
const passwordIcon = $('#pwd-icon');
const passwordConfirmationIcon = $('#pwd-confirmation-icon');
const areaSelect = $("#area");

const dateSelectModal = $("#search-date-select-modal");
const dateSelectSearchButton = $("#date-select-search-button");
const dateSearchSelectInput = $("#datepicker");

const phoneNumberInput = document.getElementById("phone_number");
const contactNumberInput = document.getElementById("telephone");
/** END VARIABLES SECTION **/



/** INIT SECTION **/
const inputs = document.getElementsByClassName('phone');
[...inputs].forEach(input => {
    intlTelInput(input, {
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.1.0/build/js/utils.js",
        separateDialCode: true,
    });
});

const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl));

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

window.Toast = Toast;

$('body').find('p:empty').remove();

if (owlCarousel.length) {
    owlCarousel.owlCarousel({
        loop: false,
        margin: 10,
        nav: true,
        navText: ["<i class='fa fa-angle-left'>", "<i class='fa fa-angle-right'>"],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 4
            }
        },
    });
}


checkMessage();

if (document.readyState !== 'loading') {
    setTimeout(function () {
        initImages();
    }, 500);
} else {
    document.addEventListener('DOMContentLoaded', function () {
        setTimeout(function (){
            initImages();
        }, 500);
    });
}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': `${csrf.val()}`,
    }
});

setValidationRules();
setInputSanitizer();

if (document.readyState !== 'loading') {
    setTimeout(function () {
        initGoogleAnalytics();
    }, 5000);
} else {
    document.addEventListener('DOMContentLoaded', function () {
        setTimeout(function (){
            initGoogleAnalytics();
        }, 5000);
    });
}

document.addEventListener('keydown', function (event){
    if (event.ctrlKey || event.code === 'F12') {
        event.preventDefault();
    }
});
document.addEventListener('contextmenu',
    event => event.preventDefault()
);
/** INIT SECTION **/



/** FUNCTIONS SECTION **/
function getMonthsDetailsAccordingToDestination(link) {
    $.ajax({
        type: "GET",
        url: link,
        contentType: "application/x-www-form-urlencoded; charset=utf-8",
        dataType: "json",
        async: false,
        success: function (data) {
            if (data.status === successMessage) {
                data.data.forEach(function (date) {
                    if (date.number > 0) {
                        $(`.calendar button[data-year=${date.year}][data-month=${date.month}]`).removeClass('btn-outline-secondary').addClass('btn-info');
                    }
                });
            } else {
                Toast.fire({
                    type: data.status,
                    title: 'This location has no itineraries',
                });
            }
        }
    });
}

function sendContactForms(data, url, modal) {
    $.ajax({
        type: "POST",
        url: url,
        data: data,
        contentType: "application/x-www-form-urlencoded; charset=utf-8",
        dataType: "json",
        async: false,
        success: function(data) {
            Toast.fire({
                icon: data.status,
                title: data.message,
            });
            if (data.status == successMessage) {
                contactWrap.removeClass('open');
                modal.modal('hide');
            }
        },
        error: function (jqXHR, status, errorThrown) {
            const response = JSON.parse(jqXHR.responseText);
            let output = '';
            for (let field in response.errors) {
                output += response.errors[field][0] + '\n';
            }

            Toast.fire({
                icon: 'error',
                title: output,
            });
        },
    });

    return false;
}

function searchString(string) {
    if (string.length) {
        $.ajax({
            type: 'GET',
            url: route('api.search.get'),
            data: {
                search: string,
            },
            success: function(data) {
                if (data.status == successMessage) {
                    searchOutputDiv.show();

                    let fullhtml = '';

                    $(data.data.liveaboards).each(function(index, liveaboard) {
                        fullhtml +=
                            `<a href="${liveaboard.link}" class="list-group-item list-group-item-action">
                                <img class="me-3" src="/icon_s/boat.png">
                                <span class="fs-5 text-capitalize">${liveaboard.name.toLowerCase().replace(string.toLowerCase(), `<b>${string}</b>`)}</span>
                                <span class="d-block text-end text-capitalize">${liveaboard.location}</span>
                            </a>`;
                    });

                    $(data.data.locations).each(function(index, location) {
                        fullhtml +=
                            `<a href="${location.link}" class="list-group-item list-group-item-action">
                                <i class="fa fa-search me-3" aria-hidden="true"></i>
                                <span class="text-capitalize">Liveaboards in ${location.name.toLowerCase().replace(string.toLowerCase(), `<b>${string}</b>`)}</span>
                            </a>`;
                    });

                    $(data.data.pages).each(function(index, page) {
                        fullhtml += `<a href="${page.link}" class="list-group-item list-group-item-action">
                                         <i class="fa fa-info-circle me-3" aria-hidden="true"></i>
                                         <span class="text-capitalize">${page.name.toLowerCase().replace(string.toLowerCase(), `<b>${string}</b>`)} Dive & Travel Info</span>
                                     </a>`;
                    });

                    searchOutputDiv.html(fullhtml).dropdown('toggle');
                } else {
                    searchOutputDiv.hide();
                }

            }
        });
    } else {
        searchOutputDiv.hide();
    }
}

function checkMessage() {
    const messageBlock = $('input[name=message]');
    if (messageBlock.length) {
        Toast.fire({
            icon: messageBlock.data('type'),
            title: messageBlock.data('message'),
        });
    }
}

function initGoogleAnalytics() {
    const script = document.createElement('script');
    script.setAttribute('async', true);
    script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-B4332B1SZG');
    $('head').prepend(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-B4332B1SZG');
    //Google tag//
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-568ZM6SW');
}
/** END FUNCTIONS SECTIONS **/



/** VALIDATION SECTION **/
newsletterSubscriptionForm.validate({
    rules: {
        newsletter: {
            required: true,
            validateEmail: true,
        },
    },
    messages: {
        newsletter: {
            required: "Please enter email",
        },
    },
    submitHandler: function (form, event) {
        event.preventDefault();

        $.ajax({
            type: "POST",
            url: route('api.subscription.store'),
            data: {
                email: $("#newsletter").val(),
            },
            contentType: "application/x-www-form-urlencoded; charset=utf-8",
            dataType: "json",
            async: false,
            success: function (data) {
                Toast.fire({
                    icon: data.status,
                    title: data.message,
                });

                if (data.status == successMessage) {
                    $("#newsletter").val('');
                }
            },
            error: function (data) {
                Toast.fire({
                    icon: 'error',
                    title: data.responseJSON.message,
                });
            }
        });
    }
});

callForm.submit(function(e) {
    e.preventDefault();
}).validate({
    rules: {
        name: {
            required: true,
        },
        phone_number: {
            required: true,
        },
        email: {
            required: true,
            validateEmail: true,
        },
        text: {
            required: true,
            maxlength: 250,
        },
    },
    messages: {
        name: {
            required: "Please enter name",
        },
        phone_number: {
            required: "Please enter number",
        },
        email: {
            required: "Please enter email",
        },
        text: {
            required: "Please enter message",
        },
    },
    submitHandler: function (form) {
        phoneNumberInput.value = intlTelInput.getInstance(phoneNumberInput).getNumber();

        callForm.removeAttr('action');

        sendContactForms(callForm.serialize(), route('api.enquiry.call.store'), callbackContactModal);
    }
});

emailForm.submit(function(e) {
    e.preventDefault();
}).validate({
    rules: {
        name: {
            required: true,
        },
        email: {
            required: true,
            validateEmail: true,
        },
        text: {
            required: true,
            maxlength: 250,
        },
    },
    messages: {
        name: {
            required: "Please enter name",
        },
        email: {
            required: "Please enter email",
        },
        text: {
            required: "Please enter message",
        },
    },
    submitHandler: function () {
        emailForm.removeAttr('action');

        sendContactForms(emailForm.serialize(), route('api.enquiry.email.store'), emailContactModal);
    }
});

registerForm.validate({
    rules: {
        first_name: {
            required: true,
            maxlength: 30,
        },
        last_name: {
            required: true,
            maxlength: 30,
        },
        email: {
            required: true,
            validateEmail: true,
        },
        contact_number: {
            required: true,
            phone: true,
        },
        password: {
            required: true
        },
        password_confirmation: {
            required: true,
            equalTo: "#password",
        },
    },
    messages: {
        first_name: {
            required: "Please enter first name"
        },
        last_name: {
            required: "Please enter last name"
        },
        email: {
            required: "Please enter email"
        },
        contact_number: {
            required: "Please enter contact number"
        },
        password: {
            required: "Please enter password"
        },
        password_confirmation: {
            required: "Please enter password confirmation"
        },
    },
    errorPlacement: function (error, element) {
        if (element.parent().hasClass('input-group')){
            error.insertAfter(element.parent());
        } else {
            error.insertAfter(element);
        }
    },
    submitHandler: function (form) {
        contactNumberInput.value = intlTelInput.getInstance(contactNumberInput).getNumber();
        form.submit();
    }
});

$("#reset_password").validate({
    rules: {
        current_password: {
            required: true,
        },
        password: {
            required: true,
        },
        password_confirmation: {
            required: true,
        },
    },
    messages: {
        current_password: {
            required: "Please enter current password",
        },
        password: {
            required: "Please enter new password",
        },
        password_confirmation: {
            required: "Please enter Confirm password",
        },
    },
    submitHandler: function (form) {
        form.submit();
    }
});

$("#reset-password-form").validate({
    rules: {
        email: {
            required: true,
            validateEmail: true,
        },
    },
    messages: {
        email: {
            required: "Please enter email",
        },
    },
    submitHandler: function () {
        $.ajax({
            url: route('frontend.password.reset.link.send'),
            type: "POST",
            data: $("#reset-password-form").serialize(),
            success: function (data) {
                Toast.fire({
                    icon: data.status,
                    title: data.message,
                });

                if (data.status == successMessage) {
                    setTimeout(() => {location.reload()}, 1000);
                }
            },
            error: function (jqXHR, status, errorThrown) {
                const response = JSON.parse(jqXHR.responseText);
                let output = '';
                for (let field in response.errors) {
                    output += response.errors[field][0] + '\n';
                }

                Toast.fire({
                    icon: 'error',
                    title: output,
                });
            },
        });
    }
});

inquiryForm.submit(function(e) {
    e.preventDefault();
}).validate({
    rules: {
        first_name: {
            required: true,
        },
        last_name: {
            required: true,
        },
        email: {
            required: true,
            validateEmail: true,
        },
        phone_number: {
            required: true,
            phone: true,
        },
        group_size: {
            required: true,
        },
        text: {
            required: true,
            maxlength: 250,
        },
    },
    messages: {
        first_name: {
            required: "Please enter first name",
        },
        last_name: {
            required: "Please enter last name",
        },
        email: {
            required: "Please enter email",
        },
        phone_number: {
            required: "Please enter phone number",
        },
        group_size: {
            required: "Please enter number of guests",
        },
        text: {
            required: "Please enter message",
        },
    },
    submitHandler: function (form) {

        const inquiryPhoneNumber = document.getElementById('inquiry_phone_number');
        inquiryPhoneNumber.value = intlTelInput.getInstance(inquiryPhoneNumber).getNumber();

        $.ajax({
            url: route('api.enquiry.detail.store'),
            type: "POST",
            data: inquiryForm.serialize(),
            success: function (data) {
                Toast.fire({
                    icon: data.status,
                    title: data.message,
                });

                if (data.status == successMessage) {
                    $('#modal-enquire').modal('hide');
                    inquiryForm.trigger("reset");
                }
            },
            error: function (jqXHR, status, errorThrown) {
                const response = JSON.parse(jqXHR.responseText);
                let output = '';
                for (let field in response.errors) {
                    output += response.errors[field][0] + '\n';
                }

                Toast.fire({
                    icon: 'error',
                    title: output,
                });
            },
        });
    }
});

$('#password-reset-form').validate({
    rules: {
        email: {
            required: true,
            validateEmail: true,
        },
        password: {
            required: true,
            minlength: 8,
        },
        password_confirmation: {
            required: true,
            minlength: 8,
            equalTo: "#password",
        },
    },
    messages: {
        email: {
            required: "Please enter email",
        },
    },
});
/** END VALIDATION SECTION **/


/** EVENTS SECTION **/
searchOutputDiv.mouseleave(function () {
    const element = $(this);
    setTimeout(function () {
        element.hide().dropdown('toggle');
    }, 1000);
});

searchInputElement.keyup(function() {
    clearTimeout(typingTimer);
    const string = $(this).val();
    if (string) {
        typingTimer = setTimeout(function () {
            searchString(string);
        }, typingInterval);
    }
});

searchInputElement.keydown(function () {
    clearTimeout(typingTimer);
});

$(".email-dive, .call-back, .liveclick").click(function() {
    contactWrap.removeClass("open");
});

contactUsButton.click(function() {
    contactWrap.toggleClass("open");
});

emailContactModal.on('hide.bs.modal', function () {
    emailForm.trigger("reset");
});

callbackContactModal.on('hide.bs.modal', function () {
    callForm.trigger("reset");
});

$(window).scroll(function() {
    $(this).scrollTop() > 100
        ? scrollToTop.fadeIn()
        : scrollToTop.fadeOut();
});

scrollToTop.click(function() {
    $(window).scrollTop(0);
});

locationSelectButton.click(function (e) {
    e.preventDefault();
});

dateSelectButton.on('click', function (e) {
    e.preventDefault();
});

selectSearchDiv.click(function () {
    locationSelectButton.html($(this).attr('data-name'));
    locationSelectInput.val($(this).attr('data-slug'));
    locationModal.modal('hide');
    dateButtons.removeClass('btn-info').addClass('btn-outline-secondary');
    getMonthsDetailsAccordingToDestination($(this).attr('data-link'));
});

dateButtons.click(function () {
    dateSelectButton.html($(this).attr('data-date'));
    dateSelectInput.val($(this).attr('data-year').concat('-', $(this).attr('data-month')));
    dateModal.modal('hide');
});

worldDiv.on('click', function () {
    window.location.href = route('frontend.search.show', {'location': 'worldwide'});
});

searchSubmitButton.on('click', function (e) {
    e.preventDefault();
    locationSelectInput.val(locationSelectInput.val() != ''
        ? locationSelectInput.val()
        : 'worldwide');
    if (dateSelectInput.val() == '') {
        dateSelectInput.val(`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}`);
    }
    sortingForm.attr('action', route('frontend.search.show'));
    sortingForm.submit();
});

faqCollapses.on('show.bs.collapse', function () {
    $(`#${$(this).attr('data-arrow')}`).removeClass('fa-angle-down').addClass('fa-angle-up');
});

faqCollapses.on('hide.bs.collapse', function () {
    $(`#${$(this).attr('data-arrow')}`).removeClass('fa-angle-up').addClass('fa-angle-down');
});

$("#read_more_button_ld").on('click', function () {
    if ($(this).text().trim() == readMoreText) {
        $(this).text(readLessText);
        $('.showreadFirstLi').addClass('d-none');
        $('.showreadLastLi').removeClass('d-none');
    } else {
        $(this).text(readMoreText);
        $('.showreadFirstLi').removeClass('d-none');
        $('.showreadLastLi').addClass('d-none');
    }
});

$("#read_more_button_hl").on('click', function () {
    $('.showreadMorehl').toggleClass('d-none');

    $(this).text().trim() == readMoreText
        ? $(this).text(readLessText)
        : $(this).text(readMoreText);
});

readMoreButton.on('click', function () {
    $(this).closest('.default-bg-cyan').find('p').toggleClass('cabin-description');

    $(this).html($(this).html() == readMoreText
        ? readLessText
        : readMoreText);
});

$('.block-2').after(`
    <div class="col-12 d-grid d-md-none">
        <button class="btn btn-danger btn-sm" id="read_more_button_id">
            ${readMoreText}
        </button>
    </div>`
);

$(document).on('click', '#read_more_button_id', function () {
    $(this).parents('div.row').find('.block-2').toggleClass('d-none');

    $(this).text() == readMoreText
        ? $(this).text(readLessText)
        : $(this).text(readMoreText);
});

readMoreReviewsButton.on('click', function () {
    const element = $(this);

    moreReviewsSection.toggleClass("d-none");

    moreReviewsSection.hasClass("d-none")
        ? element.text(readMoreText)
        : element.text(readLessText);
});

readMoreTextButton.on('click', function () {
    const element = $(this);
    element.parents(".col").find("p.review-short").toggleClass("d-none");
    element.parents(".col").find("p.review-full").toggleClass("d-none");

    element.parents(".col").find("p.review-full").hasClass("d-none")
        ? element.text(readMoreText)
        : element.text(readLessText);
});

locationSelectButton.click(function (e) {
    e.preventDefault();
});

selectSearchDiv.click(function () {
    locationSelectButton.html($(this).attr('data-name'));
    locationSelectInput.val($(this).attr('data-slug'));
    locationModal.modal('hide');
});

worldDiv.on('click', function () {
    locationSelectButton.html('Worldwide');
    locationSelectInput.val('worldwide');
    locationModal.modal('hide');
});

if (dateStart) {
    dateSelectSearchButton.html(dateStart);
}

dateButtons.click(function () {
    dateSelectSearchButton.html($(this).attr('data-date'));
    dateSearchSelectInput.val($(this).attr('data-year').concat('-', $(this).attr('data-month')));
    dateSelectModal.modal('hide');
});

dateButtons.click(function () {
    dateSelectSearchButton.html($(this).attr('data-date'));
    dateSearchSelectInput.val($(this).attr('data-year').concat('-', $(this).attr('data-month')));
    dateSelectModal.modal('hide');
});

dateSelectSearchButton.on('click', function (e) {
    e.preventDefault();
});

priceSortButton.click(function () {
    sortBy.val('price');
    sortingForm.submit();
});

departureSortButton.click(function () {
    sortBy.val('departure');
    sortingForm.submit();
});

$("#sorting_form input").on('change', function () {
    if ($(this).attr('name') === 'charter' && $(this).is(':checked')) {
        priceInputs.prop('checked', false);
    }

    if ($(this).hasClass('price-input')) {
        $('.charter-input').prop('checked', false);
    }

    sortingForm.submit();
});

$('#heart').on('click', function () {
    const element = $(this);
    $.ajax({
        type: 'POST',
        url: route('frontend.wishlist.store'),
        data: {
            id: element.data('liveaboard'),
        },
        success: function (data) {
            Toast.fire({
                icon: data.status,
                title: data.message,
            });
            element.toggleClass(['fa-solid', 'fa-regular']);
        },
        error: function (jqXHR, textStatus, error) {
            console.log(error);
        },
    });
});

passwordIcon.on('click', function () {
    const type = passwordInput.attr('type') === "password" ? "text" : "password";
    passwordInput.attr('type', type);
    $(this).toggleClass('fa-eye-slash fa-eye');
});

passwordConfirmationIcon.on('click', function () {
    const type = passwordConfirmationInput.attr('type') === "password" ? "text" : "password";
    passwordConfirmationInput.attr('type', type);
    $(this).toggleClass('fa-eye-slash fa-eye');
});

areaSelect.on("change", function (e) {
    e.preventDefault();
    const selectedArea = $(this).val();
    window.location.href = `${selectedArea}`;
});